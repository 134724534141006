
/*
 This is a simple hook to efficiently get the query-string from the current page URL.
 */

import { useEffect, useState } from 'react';
import axios from 'axios'
import { useQuery, useSearchParams } from 'react-query'
import {apiBaseUrl} from "../utils/utils";

function useSearchTrains(toc, from, to, geofence, mode, route) {


    const [searchTrains, setSearchTrains] = useState();
    const [searchRids, setSearchRids] = useState()

    ///api/trains?toc=VT
    let params = {}
    if (toc !== undefined) {
        params['toc'] = toc
    }
    if (from !== undefined) {
        params['origin'] = from
    }
    if (to !== undefined) {
        params['destination'] = to
    }
    if (geofence !== undefined) {
        params['lat'] = geofence['lat']
        params['lon'] = geofence['lon']
        params['radius'] = geofence['radius']
    }
    if (mode !== undefined) {
        params['mode'] = mode
    }
    if (route !== undefined) {
        params['route'] = route
    }
    const qs = new URLSearchParams(params);

    const base_url = apiBaseUrl();
    //TODO fetch interval needs to be modified
    const { isLoading, isError, data, error, refetch } = useQuery(['trains', toc], () => { return axios.get(`${base_url}/api/trains?${qs.toString()}`) },
        {
            enabled: false,
        })

    // This where we trigger a refetch.
    useEffect(() => {

        if (toc !== undefined || from !== undefined || to !== undefined || geofence !== undefined || mode !== undefined || route !== undefined) {
            refetch()
        } else {
            setSearchTrains(undefined)
            setSearchRids(undefined)
        }

    }, [toc, from, to, geofence, mode, route])

    // This is where we unpack the data that we fetch.
    useEffect(() => {
        if (data !== undefined) {
            // console.log(data.data.trains)
            setSearchTrains(data.data.trains)
            setSearchRids(data.data.trains.map((train) => { return train.rid }))
        }
    }, [data])


    return { isLoading, isError, searchTrains, searchRids }

}

export default useSearchTrains;
