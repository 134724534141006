



const DefaultTheme = {
    name: 'default',
    mapboxStyle: "mapbox://styles/signalbox/cm0pdajua00kr01o3gwkkgvbl",
    viewportDefaults: {
        latitude: 54.67538,
        longitude: -5,
        zoom: 5,
    },
    navigationControlStyle: { right: 12, top: 12 },
    geolocateControlStyle : { right: 12, top: 140 },
    routeLineColor: "#74CEB7"
}

  export default DefaultTheme;
