import React, { memo, useEffect, ReactDOM, ReactSVG } from 'react';
import { Layer, Source, useMap, } from "react-map-gl";
import useFeatures from './hooks/useFeatures';
import { useMapImage } from './hooks/useMapImage';
import TrainIcon from './TrainIcon';
import useComponentToImage from './hooks/useComponentToImage';
import useIconDiameter from './hooks/useIconDiameter';


function TrainIconLayer(props) {
  const {
    currentTrains,
    zoom,
    currentTrain,
    Icon = TrainIcon,
    focusSize = 1.6,
    iconMagnifcation = 0.7,
    colorRagRed = '#FF6120',
    colorRagAmber = '#FFC508',
    colorRagGreen = '#00A88F',
  } = props;


  const iconDiameter = useIconDiameter(zoom) * iconMagnifcation

  // Generate features geojson
  const features = useFeatures(iconDiameter, focusSize, currentTrains, currentTrain)

  // Components to images. 
  const imgRed = useComponentToImage(<Icon color={colorRagRed} />)
  const imgAmber = useComponentToImage(<Icon color={colorRagAmber} />)
  const imgGreen = useComponentToImage(<Icon color={colorRagGreen} />)

  // Add images to map 
  useMapImage(imgRed, 'red')
  useMapImage(imgAmber, 'amber')
  useMapImage(imgGreen, 'green')

  const style = {
    type: 'symbol',
    layout: {
      'icon-image': ['get', 'icon'],
      'icon-allow-overlap': true,
      'icon-size': ['get', 'iconSize'],
      'icon-rotate': ['get', 'rotation']
    },
    afterId: 'stations'
  };


  return (
    <div className='TrainIconLayer'>
      <Source id="train_icon" type="geojson" data={features}>
        <Layer id="train_icon"
          {...style}
        />
      </Source>
    </div>
  );
}



export default React.memo(TrainIconLayer);
