import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { createQueryString, parseViewParameter, createLocationParameter, parseGeofenceParameter, createGeofenceParameter } from "utils/utils.js"
import useDebounce from './useDebounce';
import useTrainInfo from './useTrainInfo';
import { useTranslation } from 'react-i18next'

/*
 This hook is used for maintaining the state capture in the url optional parameters
 */

function useMapParams() {

  // These are all the parameters that are stored in the URL. 
  const [currentTrain, setCurrentTrain] = useState(undefined);
  const [toc, setToc] = useState(undefined)
  const [from, setFrom] = useState(undefined)
  const [to, setTo] = useState(undefined)
  const [area, setArea] = useState(undefined)
  const [coloring, setColoring] = useState(undefined); // the variable used to color markers
  const [chrome, setChrome] = useState(undefined); // removes chrome
  const [tracking, setTracking] = useState(false)
  const [view, setView] = useState(undefined)
  const [themeName, setThemeName] = useState(undefined); // the theme for whitelabeling. 
  const [lang, setLang] = useState(undefined); //language (cy for welsch)
  const [mode, setMode] = useState(undefined); //transport mode
  const [route, setRoute] = useState(undefined); //transport mode

  const [queryString, setQueryString] = useState("");
  const debouncedQueryString = useDebounce(queryString, 500)
  const navigate = useNavigate();

  const { isLoading, isError, trainInfo, setTrainId } = useTrainInfo(setCurrentTrain)

  const { t, i18n } = useTranslation();

  // TODO consider adding debounce
  // Location parameter, debounced.
  // const debouncedLocation = useDebounce(mapLocation, 500);
  // Todo consider changing location format.

  // This effect reads in all the url parameters
  useEffect(() => {

    const params = new URLSearchParams(window.location.search)
    
    if (params.get("train") !== null) {
      if (params.get("train").length === 6) {
        setTrainId(params.get("train"))
      } else {
        setCurrentTrain(params.get("train"))
      }
    }
    if (params.get("toc") !== null) {
      setToc(params.get("toc"))
    }
    if (params.get("from") !== null) {
      setFrom(params.get("from")) //from station
    }
    if (params.get("to") !== null) {
      setTo(params.get("to")) //from station
    }
    if (params.get("area")) {
      setArea(parseGeofenceParameter(params.get("area"))) //geofence
    }
    if (params.get("coloring") !== null) {
      setChrome(params.get("coloring"))
    }
    if (params.get("chrome") !== null) {
      setChrome(params.get("chrome"))
    }
    if (params.get("theme") !== null) {
      setThemeName(params.get("theme"))
    }
    if (params.get("location") !== null) {
      setView(parseViewParameter(params.get("location")))
    }
    if (params.get('lang') !== null) {
      setLang(params.get("lang"))
    }
    if (params.get('mode') !== null) {
      setMode(params.get("mode"))
    }
    if (params.get('route') !== null) {
      setRoute(params.get("route"))
    }
  }, [])

  useEffect(() => {

    const searchParams = {}

    if (currentTrain !== undefined) {
      searchParams['train'] = currentTrain
    }

    if (view !== undefined) {
      searchParams["location"] = createLocationParameter(view)
    }

    if (toc !== undefined) {
      searchParams["toc"] = toc;
    }

    if (from !== undefined) {
      searchParams["from"] = from;
    }

    if (to !== undefined) {
      searchParams["to"] = to;
    }

    if (area !== undefined) {
      searchParams["area"] = createGeofenceParameter(area);
    }

    if (coloring !== undefined) {
      searchParams["coloring"] = coloring;
    }
    if (chrome !== undefined) {
      searchParams["chrome"] = chrome;
    }
    if (themeName !== undefined) {
      searchParams["theme"] = themeName;
    }
    if (lang !== undefined) {
      searchParams["lang"] = lang;
    }
    if (mode !== undefined) {
      searchParams["mode"] = mode;
    }
    if (route !== undefined) {
      searchParams["route"] = route;
    }
    
    const queryString = createQueryString(searchParams)

    setQueryString(queryString)

    // navigate({search: "?" + q});
  }, [currentTrain, toc, from, to, area, coloring, chrome, tracking, view, themeName, lang, mode, route]);

  useEffect(() => {
    navigate({ search: "?" + debouncedQueryString });
  }, [debouncedQueryString])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])


  return {
    currentTrain,
    setCurrentTrain,
    toc,
    setToc,
    from,
    setFrom,
    to,
    setTo,
    area,
    setArea,
    coloring,
    setColoring,
    chrome,
    setChrome,
    tracking,
    setTracking,
    view,
    setView,
    themeName,
    setThemeName,
    lang,
    setLang,
    mode,
    setMode,
    route,
    setRoute
  }
}

export default useMapParams;


